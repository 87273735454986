import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Box, Container, Flex, Heading } from "theme-ui";
import ThemedMarkdown from "../components/themed-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type Props = {
  data: {
    markdownRemark: any;
  };
  location: string;
};

const MarkdownColumnsPage = ({ data, location }: Props) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      seo={{
        title: frontmatter.title,
        description: frontmatter.seoDescription,
        pathname: location,
      }}
    >
      <Container my={5}>
        <Heading as="h1" variant="styles.h2" mb={4}>
          {frontmatter.h1}
        </Heading>
        <Flex
          sx={{
            flexDirection: ["column", "column", "row"],
            mb: 4,
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          {frontmatter.columns.map((col, i) => (
            <Flex key={i} sx={{ flexDirection: "column", mx: 4, flex: "1 1 0" }}>
              <GatsbyImage sx={{ flex: "1 1 0" }} image={getImage(col.image)} alt={col.alttext} />
              <ThemedMarkdown html={col.markdown} />
            </Flex>
          ))}
        </Flex>
        {frontmatter.heroblock.map((block, i) => (
          <Flex key={i} sx={{ flexDirection: "column" }}>
            <GatsbyImage
              sx={{ flex: "1 1 0", my: 4 }}
              image={getImage(block.image)}
              alt={block.alttext}
            />
            <ThemedMarkdown html={block.markdown} />
          </Flex>
        ))}
      </Container>
    </Layout>
  );
};

export default MarkdownColumnsPage;

export const pageQuery = graphql`
  query MarkdownColumnsPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        seoDescription
        title
        h1
        columns {
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 92
                layout: CONSTRAINED
                aspectRatio: 1
                placeholder: BLURRED
              )
            }
          }
          alttext
          markdown
        }
        heroblock {
          image {
            childImageSharp {
              gatsbyImageData(quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          alttext
          markdown
        }
      }
    }
  }
`;
